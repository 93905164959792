import React from "react";
import ReactDOM from "react-dom";
import {QueryClientProvider} from "react-query";

import ErrorBoundary from "components/error/ErrorBoundary";
import AlertsProvider from "components/shared/AlertsProvider";
import LoginSignup from "components/login-signup";
import {getDefaultQueryClient} from "util/query";

const queryClient = getDefaultQueryClient();

ReactDOM.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <AlertsProvider>
        <LoginSignup isLogin={true}/>
      </AlertsProvider>
    </QueryClientProvider>
  </ErrorBoundary>,
  document.getElementById("app"),
);
